import * as Sentry from "@sentry/browser";
import * as amplitude from "@amplitude/analytics-browser";
import { onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { useRouter } from "next/router";
import nookies from "nookies";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OpraUser } from "types/domain";
import { auth, firestore } from "utils/firebase/client";
import * as logger from "utils/logger";
import { setIdTokenCookie, setRefreshTokenCookie } from "./cookie-utils";

/** The different statuses of a user */
type LoginStatus = "LOGGED_IN" | "LOADING" | "ERROR" | "NOT_LOGGED_IN";

type AuthContextType = {
  user: OpraUser | null;
  loginStatus: LoginStatus;
};
const AuthContext = createContext<AuthContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
  user?: OpraUser;
};
/** Fetches the user and makes it available via hooks
 *
 * The hooks you want to use are useAuth, useRequiredAuth, and useRequiredActiveUser
 */
export const AuthProvider = ({
  user: initialUser,
  children,
}: AuthProviderProps) => {
  const [user, setUser] = useState<OpraUser | null>(initialUser ?? null);
  const [loginStatus, setLoginStatus] = useState<LoginStatus>(
    user ? "LOGGED_IN" : "LOADING"
  );

  useEffect(() => {
    let cancelUserListener = () => {};
    const cancelAuthListener = onAuthStateChanged(auth, async (nextUser) => {
      if (cancelUserListener) {
        cancelUserListener();
      }
      try {
        if (nextUser) {
          cancelUserListener = onSnapshot(
            doc(firestore, "users", nextUser.uid),
            async (nextEntry) => {
              if (!nextEntry.exists()) {
                return;
              }
              const identification = new amplitude.Identify();
              identification.set("uid", nextUser.uid);
              if (nextUser.email) {
                identification.set("email", nextUser.email);
              }
              amplitude.identify(identification);
              const data = (nextEntry.data() || null) as OpraUser | null;
              setUser(data);
              setLoginStatus(data ? "LOGGED_IN" : "NOT_LOGGED_IN");
            }
          );
        } else {
          setUser(null);
          setLoginStatus("NOT_LOGGED_IN");
        }
      } catch (e) {
        Sentry.captureException(e);
        setUser(null);
        setLoginStatus("ERROR");
        logger.error("error while fetching user", e);
      }
    });
    return () => {
      cancelUserListener();
      cancelAuthListener();
    };
  }, []);

  useAuthTokenInCookie();
  useRenewIdTokenEveryTenMinutes();

  const contextValue = useMemo(
    () => ({
      user,
      loginStatus,
    }),
    [user, loginStatus]
  );
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const useAuthTokenInCookie = () => {
  useEffect(() => {
    return onIdTokenChanged(auth, async (user) => {
      if (!user) {
        nookies.destroy(undefined, "token");
        nookies.destroy(undefined, "refreshToken");
      } else {
        const token = await user.getIdToken();

        setIdTokenCookie(token);
        setRefreshTokenCookie(user.refreshToken);
      }
    });
  }, []);
};

const useRenewIdTokenEveryTenMinutes = () => {
  useEffect(() => {
    const handle = setInterval(async () => {
      if (auth.currentUser) {
        await auth.currentUser.getIdToken(true);
      }
    }, 10 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);
};

/** Returns the auth object
 *
 * This can be used to check if the user is logged in or not, but is mostly
 * used internally to consume the auth context safely
 */
export const useAuth = (initialUser?: OpraUser) => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "You must wrap this component in a <AuthProvider /> component."
    );
  }
  return {
    ...context,
    isLoading: context.loginStatus === "LOADING",
    user: context.user ?? initialUser ?? null,
  };
};

/** Ensures the user is logged in.
 *
 * If the user isn't logged in, they will be redirected to the login page,
 * and then redirected back after successfully logging in.
 *
 * @returns The auth object, including the user if logged in
 */
export const useRequiredAuth = () => {
  const router = useRouter();
  const auth = useAuth();
  if (auth.loginStatus === "NOT_LOGGED_IN") {
    router.push(`/logg-inn?redirect=${router.asPath}`);
  }
  return auth;
};

/** Ensures the user is logged in and has an active subscription.
 *
 * If the user hasn't paid, they will be redirected to the payment page.
 *
 * @returns The auth object, including the user if logged in
 */
export const useRequiredActiveUser = () => {
  const auth = useRequiredAuth();
  const router = useRouter();
  // If you're not an active user,
  // you should be redirected to the payment page
  switch (auth.user?.subscriptionStatus) {
    case "ACTIVE":
      break;
    case undefined:
      break;
    default:
      router.push("/profil");
      break;
  }

  return auth;
};
