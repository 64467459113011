/**
 * Resolves the locale of any objects in an object
 *
 * Used for transforming localized data to "regular" data, so to speak
 */
export function resolveLocalizedData<T>(
  locale: string,
  data: T
): extractLanguage<T> {
  if (Array.isArray(data)) {
    return data.map((item) => resolveLocalizedData(locale, item)) as any;
  }
  if (typeof data !== "object" || data === null) {
    return data as any;
  }
  const anyData = data as any;
  if (anyData[locale] !== undefined) {
    return anyData[locale];
  }
  if (anyData._type?.startsWith("locale") && anyData.no !== undefined) {
    if (process.env.NODE_ENV === "development") {
      console.warn(
        `Missing translation for locale "${locale}" on object of type "${anyData._type}". Falling back to Norwegian translation`,
        anyData.no
      );
    }

    return anyData.no;
  }

  return Object.entries(data as any).reduce((prev, [key, value]) => {
    prev[key] = resolveLocalizedData(locale, value);
    return prev as any;
  }, {} as any);
}

type extractLanguage<T> = T extends {}
  ? {
      [K in keyof T]: T[K] extends { no: infer N; en: infer N }
        ? N
        : T[K] extends {}
        ? extractLanguage<T[K]>
        : extractLanguage<T[K]>;
    }
  : T extends Array<infer A>
  ? extractLanguage<A>
  : T;
