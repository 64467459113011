import { useRouter } from "next/router";
import React from "react";
import { SanityImage } from "types/domain";
import { resolveLocalizedData } from "utils/locale";

export type Settings = {
  title: LocaleString;
  description: LocaleString;
  keywords: LocaleTags;
  ogImage: SanityImage;
  mainNavigation: NavigationItem[];
  footerNavigation: NavigationItem[];
  legalNavigation: NavigationItem[];
};

const SettingsContext = React.createContext<Settings | null>(null);
type SettingsProviderProps = {
  children: React.ReactNode;
  value: Settings;
};
export const SettingsProvider = (props: SettingsProviderProps) => {
  return (
    <SettingsContext.Provider value={props.value}>
      {props.children}
    </SettingsContext.Provider>
  );
};

/**
 * Gets the site wide settings
 */
export const useSettings = () => {
  const { locale } = useRouter();
  const settings = React.useContext(SettingsContext);
  if (!settings) {
    return null;
  }
  return resolveLocalizedData((locale as "no" | "en") ?? "no", settings);
};

type LocaleString = {
  no: string;
  en: string;
};

type LocaleTags = {
  no: string[];
  en: string[];
};

type NavigationItem = {
  availability: "allUsers" | "loggedOutUsers" | "loggedInUsers" | "activeUsers";
  title: LocaleString;
  url: LocaleString;
};
