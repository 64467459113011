import { GetServerSidePropsContext } from "next";
import nookies from "nookies";

export const setIdTokenCookie = (
  idToken: string,
  context?: GetServerSidePropsContext
) =>
  nookies.set(context, "token", idToken, {
    path: "/",
    maxAge: 10 * 60,
  });
export const setRefreshTokenCookie = (
  idToken: string,
  context?: GetServerSidePropsContext
) =>
  nookies.set(context, "refreshToken", idToken, {
    path: "/",
    maxAge: 30 * 24 * 60 * 60,
  });
