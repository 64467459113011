import urlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { createClient } from "@sanity/client";

export const config = {
  projectId: "4d1a5440",
  dataset: "production",
  useCdn: process.env.NODE_ENV === "production",
  apiVersion: "2024-07-03",
};

/** The Sanity Client used for fetching data */
const client = createClient(config);

/** A tool for creating image URLs */
export const urlFor = (image: SanityImageSource) =>
  urlBuilder(config).image(image).withOptions({ format: "webp", quality: 60 });

export default client;
