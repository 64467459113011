import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import config from "./config";

const app = initializeApp(config);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, "europe-west1");
export const storage = getStorage(app);
