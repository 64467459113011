import { ChakraProvider } from "@chakra-ui/react";
import { AmplitudeContextProvider } from "features/analytics/amplitude";
import { Settings, SettingsProvider } from "features/settings/SettingsContext";
import groq from "groq";
import App, { AppProps } from "next/app";
import { AuthProvider } from "utils/auth/auth-utils";
import client from "utils/sanity";
import theme from "utils/theme";

const RootApp = ({
  Component,
  pageProps,
  err,
  settings,
}: AppProps & { err: any; settings: Settings }) => {
  return (
    <AuthProvider>
      <AmplitudeContextProvider>
        <SettingsProvider value={settings}>
          <ChakraProvider theme={theme}>
            <Component {...pageProps} err={err} />
          </ChakraProvider>
        </SettingsProvider>
      </AmplitudeContextProvider>
    </AuthProvider>
  );
};

RootApp.getInitialProps = async (appContext: any) => {
  const [settings, appProps] = await Promise.all([
    client.fetch(groq`*[_type == "siteSettings"][0]`),
    App.getInitialProps(appContext),
  ]);
  return { ...appProps, settings };
};

export default RootApp;
