import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export default extendTheme({
  config: {
    initialColorMode: "light",
  },
  fonts: {
    body: '"IBM Plex Sans", sans-serif',
    heading: '"IBM Plex Sans", sans-serif',
    mono: '"Dank Mono", monospace',
  },
  styles: {
    global: (props: any) => ({
      html: {
        scrollBehavior: "smooth",
      },
      body: {
        color: mode("brand.slateBlack", "brand.pureWhite")(props),
        backgroundColor: mode("brand.pureWhite", "brand.slateBlack")(props),
      },
    }),
  },
  colors: {
    brand: {
      emergencyYellow: "#FFCE07",
      slateBlack: "#222222",
      paleGray: "#BCBCBC",
      foggyWhite: "#F0EFEF",
      almostWhite: "#F2F2F2",
      pureWhite: "#FFFFFF",
    },
    yellow: {
      50: "#fffae4",
      100: "#ffeea9",
      200: "#ffdf61",
      300: "#ffcd08",
      400: "#edbe07",
      500: "#d9ae06",
      600: "#c19b05",
      700: "#a68505",
      800: "#836904",
      900: "#4d3e02",
    },
  },
});
