import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { createContext, useContext, useEffect } from "react";
import { useAuth } from "utils/auth/auth-utils";
import { auth } from "utils/firebase/client";

const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string;

const AmplitudeContext = createContext({});

type AmplitudeContextProviderProps = {
  children: React.ReactNode;
};
export const AmplitudeContextProvider = ({
  children,
}: AmplitudeContextProviderProps) => {
  useEffect(() => {
    amplitude.init(AMPLITUDE_API_KEY, auth.currentUser?.email ?? undefined, {
      defaultTracking: {
        sessions: true,
      },
    });
    amplitude.add(sessionReplayPlugin());
  }, []);
  const trackAmplitudeEvent = (
    eventName: string,
    eventProperties: Record<string, any>
  ) => {
    amplitude.track(eventName, eventProperties);
  };

  const value = { trackAmplitudeEvent };

  return (
    <AmplitudeContext.Provider value={value}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export const useAmplitude = () => {
  const context = useContext(AmplitudeContext);
  if (context === undefined)
    throw new Error(
      "useAmplitudeContext must be used within a AmplitudeContextProvider"
    );
  return context;
};
