const __DEV__ = process.env.NODE_ENV === "development";
const noop = () => {};

/** Only logs debug messages in development */
export const debug = __DEV__ ? console.debug.bind(console) : noop;

/** Only logs info messages in development */
export const info = __DEV__ ? console.info.bind(console) : noop;

/** Only logs warn messages in development */
export const warn = __DEV__ ? console.warn.bind(console) : noop;

/** Only logs error messages in development */
export const error = __DEV__ ? console.error.bind(console) : noop;
